<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-container topBottom>
      <div v-if="isMissingData" class="missing-data">
        {{ $t('users.edit.alertMissing') }}
        <br />
        <br />
      </div>
      <v-card elevation="0">
        <v-card-text>
          <v-form ref="form">
            <div v-if="$store.state.auth.user.type != 'student'">
              <f-flags />
              <br />
            </div>
            <f-main-form />
            <br />
            <f-champion-settings />
            <br />
            <f-general-settings />
          </v-form>
        </v-card-text>
      </v-card>
      <br />
      <lms-btn-holder>
        <v-btn
          color="primary"
          large
          elevation="0"
          @click="save"
          :disabled="flags.sending"
          :loading="flags.sending"
        >
          {{ flags.isEditing ? $t('labels.save') : $t('labels.create') }}
        </v-btn>
        <v-btn large depressed @click="back">
          {{ $t('labels.cancel') }}
        </v-btn>
      </lms-btn-holder>
    </f-container>
  </div>
</template>

<script>
import { UserType } from '../../helpers/user_utils';
import FFlags from '../../components/Users/createEdit/Flags.vue';
import FMainForm from '../../components/Users/createEdit/MainForm.vue';
import FChampionSettings from '../../components/Users/createEdit/ChampionSettings.vue';
import FGeneralSettings from '../../components/Users/createEdit/GeneralSettings.vue';

export default {
  components: {
    FFlags,
    FMainForm,
    FChampionSettings,
    FGeneralSettings,
  },
  data: () => ({
    id: null,
    flags: {
      loaded: false,
      isEditing: false,
      sending: false,
    },
    isMissingData: false,
  }),
  computed: {
    user() {
      return this.$store.state.user.view;
    },
    prevLinks() {
      return [
        {
          title: this.$t('labels.users'),
          route: {
            name: 'user-list',
          },
        },
      ];
    },
    title() {
      let title = this.$t('labels.create');
      if (this.$store.getters['system/isEditPage']) {
        title = this.$t('labels.edit');
      }
      return title;
    },
  },
  async created() {
    const action = this.$route.name != 'user-edit' ? 'create' : 'update';

    if (this.$route.name == 'user-edit') {
      this.flags.isEditing = true;
      this.id = this.$route.params.id;
      this.$store.commit('user/setID', this.id);
      this.get();
    } else {
      this.$store.state.user.view = {
        TSM: {
          countries: [],
        },
        classification: [],
        trainingCategory: [],
      };
    }

    this.$gtm.trackEvent({
      category: 'users-admin-actions',
      event: 'click',
      action: 'users-click',
      label: `${action}-user`,
      value:
        this.$route.name != 'user-edit'
          ? 'user-create'
          : `update-${this.$route.params.id}`,
    });
  },
  methods: {
    async get() {
      const result = await this.$store.dispatch('user/get');

      if (!result) {
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.users.notFound'),
          type: 'error',
        });
        this.back();
        return;
      }

      if (this.user.isTSM) {
        this.TSMState = true;
        this.setTSMRegion();
      }

      this.flags.loaded = true;

      if (
        !this.user.accept &&
        this.$store.state.auth.user.id == this.user._id
      ) {
        this.$store.state.system.isLocked = true;
        this.$router.push({
          name: 'profile-privacy',
        });
      }

      if (
        this.user._id == this.$store.state.auth.user.id &&
        (!this.user.country || !this.user.dialCode)
      ) {
        this.isMissingData = true;
        this.$refs.form.validate();
      }
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.users.errorSaved'),
          type: 'error',
        });
        return false;
      }

      const payload = {
        ...this.user,
        masters: Array.isArray(this.user.masters)
          ? this.user.masters
          : [this.user.masters],
      };
      if (this.$store.getters['system/isEditPage']) {
        const result = await this.$store.dispatch('user/update', payload);
        if (+result.status !== 1) {
          this.$store.commit('snackbar/show', {
            content: this.$t(`apiStatus.${result.status}`),
            type: 'error',
          });
        } else {
          if (this.user._id === this.$store.state.user.id) {
            this.$i18n.locale = this.user.lang;
            this.$vuetify.lang.current = this.user.lang;
          }
          this.$store.commit('snackbar/show', {
            content: this.$t('alerts.general.successEdit'),
            type: 'success',
          });
        }
      } else {
        const result = await this.$store.dispatch('user/create', payload);
        if (+result.status !== 1) {
          this.$store.commit('snackbar/show', {
            content: this.$t(`apiStatus.${result.status}`),
            type: 'error',
          });
        } else {
          this.$store.commit('snackbar/show', {
            content: this.$t('alerts.general.successCreate'),
            type: 'success',
          });
        }
      }
      this.$store.state.auth.user.type === UserType.Admin ||
      this.$store.state.auth.user.type === UserType.Master
        ? this.back()
        : this.$router.push({ name: 'course-list' });
    },
    back() {
      this.$router.push({
        name: 'user-list',
      });
    },
    onChangeIsMaster() {
      this.user.masters = [];
    },
  },
};
</script>

<style lang="css" scoped>
.missing-data {
  color: red;
  padding-left: 16px;
}
</style>
