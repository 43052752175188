<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" v-if="$store.state.auth.user.type !== 'student'">
        <f-group-container class="user-lang">
          <p>{{ $i18n.t('labels.userStatus') }}</p>
          <v-btn-toggle v-model="user.status" class="show--desktop">
            <v-btn value="pending">{{
              $i18n.t('labels.status.pending')
            }}</v-btn>
            <v-btn value="enabled">{{
              $i18n.t('labels.status.enabled')
            }}</v-btn>
            <v-btn value="disabled">{{
              $i18n.t('labels.status.disabled')
            }}</v-btn>
            <v-btn value="rejected">{{
              $i18n.t('labels.status.rejected')
            }}</v-btn>
          </v-btn-toggle>
          <v-select
            class="show--mobile"
            v-model="user.status"
            :items="[
              { text: $i18n.t('labels.status.pending'), value: 'pending' },
              { text: $i18n.t('labels.status.enabled'), value: 'enabled' },
              { text: $i18n.t('labels.status.disabled'), value: 'disabled' },
              { text: $i18n.t('labels.status.rejected'), value: 'rejected' },
            ]"
            :label="$tt.capitalize($t('labels.type'))"
            :rules="valid"
            outlined
            hide-details
          />
        </f-group-container>
      </v-col>

      <v-col cols="12" md="6">
        <f-group-container class="user-lang">
          <p>{{ $i18n.t('labels.language') }}</p>
          <v-btn-toggle
            v-model="user.lang"
            @change="onChangeLang"
            class="show--desktop"
          >
            <v-btn value="pt">{{ $t('loginPage.langs.portuguese') }}</v-btn>
            <v-btn value="en">{{ $t('loginPage.langs.english') }}</v-btn>
            <v-btn value="es">{{ $t('loginPage.langs.spanish') }}</v-btn>
          </v-btn-toggle>
          <v-select
            class="show--mobile"
            v-model="user.lang"
            :items="[
              { text: $t('loginPage.langs.portuguese'), value: 'pt' },
              { text: $t('loginPage.langs.english'), value: 'en' },
              { text: $t('loginPage.langs.spanish'), value: 'es' },
            ]"
            :label="$tt.capitalize($t('labels.type'))"
            :rules="valid"
            outlined
            hide-details
          />
        </f-group-container>
      </v-col>
    </v-row>

    <v-row v-if="$store.state.auth.user.type !== 'student'">
      <v-col span="12">
        <div v-if="flags.isEditing">
          <v-btn color="error" large elevation="0" @click="resetPass">
            {{ $t('labels.resetPassword') }}
          </v-btn>
        </div>
        <v-text-field
          v-if="!flags.isEditing"
          id="user-edit-password"
          outlined
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          ref="user-edit-password"
          name="user-edit-password"
          v-model="user.password"
          :label="$tt.capitalize($t('labels.password'))"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FGroupContainer from '../../shared/GroupContainer.vue';
import validatorRules from '../../../helpers/validator_rules';

export default {
  components: {
    FGroupContainer,
  },
  data: () => ({
    showPassword: false,
    flags: {
      loaded: false,
      isEditing: false,
      enabledChampion: false,
    },
  }),
  computed: {
    user() {
      return this.$store.state.user.view;
    },
    valid() {
      return validatorRules.required(this.$t('rules.required'));
    },
  },
  created() {
    if (this.$route.name == 'user-edit') {
      this.flags.isEditing = true;
    }
  },
  methods: {
    onChangeLang(value) {
      this.user.lang = value;
    },
    async resetPass() {
      await this.$store.dispatch('user/recoveryPassword', this.user.email);
      this.$store.commit('snackbar/show', {
        content: this.$t('loginPage.pages.forgot.success'),
        type: 'success',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-lang {
  padding-top: 18px;
  padding-bottom: 20px;
}
</style>
