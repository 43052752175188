import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VSelect,{attrs:{"items":[
      { text: _vm.$i18n.t('labels.student'), value: 'student' },
      { text: 'Master', value: 'master' },
      { text: 'FACT', value: 'fact' },
      { text: 'TSM', value: 'tsm' },
      { text: 'Admin', value: 'admin' },
    ],"label":_vm.$tt.capitalize(_vm.$t('labels.userType')),"rules":_vm.valid,"required":"","outlined":"","hide-details":""},model:{value:(_vm.user.type),callback:function ($$v) {_vm.$set(_vm.user, "type", $$v)},expression:"user.type"}}),_c('br'),_c('br'),(_vm.showSelect.master)?_c('f-master-select',{attrs:{"isMultiple":false},model:{value:(_vm.user.masters),callback:function ($$v) {_vm.$set(_vm.user, "masters", $$v)},expression:"user.masters"}}):_vm._e(),(_vm.user.type == 'student' || _vm.user.type == 'fact')?_c('f-master-select',{attrs:{"isMultiple":false},model:{value:(_vm.user.masters),callback:function ($$v) {_vm.$set(_vm.user, "masters", $$v)},expression:"user.masters"}}):_vm._e(),(_vm.user.type == 'tsm')?_c('div',[_c(VSelect,{attrs:{"item-value":"_id","item-text":"name","items":_vm.countriesList,"label":_vm.$tt.capitalize(_vm.$t('labels.country')),"rules":_vm.valid,"required":"","outlined":"","multiple":"","chips":""},on:{"change":function($event){return _vm.setTSMRegion()}},model:{value:(_vm.user.TSM.countries),callback:function ($$v) {_vm.$set(_vm.user.TSM, "countries", $$v)},expression:"user.TSM.countries"}}),(_vm.user.TSM.countries.includes(_vm.brazilCountryID) !== false)?_c('div',[_c(VSelect,{attrs:{"items":_vm.TSMStates,"label":_vm.$tt.capitalize(_vm.$t('labels.states')),"outlined":"","multiple":"","chips":""},model:{value:(_vm.user.TSM.regions),callback:function ($$v) {_vm.$set(_vm.user.TSM, "regions", $$v)},expression:"user.TSM.regions"}})],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }