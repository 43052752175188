import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[(_vm.$store.state.auth.user.type !== 'student')?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('f-group-container',{staticClass:"user-lang"},[_c('p',[_vm._v(_vm._s(_vm.$i18n.t('labels.userStatus')))]),_c(VBtnToggle,{staticClass:"show--desktop",model:{value:(_vm.user.status),callback:function ($$v) {_vm.$set(_vm.user, "status", $$v)},expression:"user.status"}},[_c(VBtn,{attrs:{"value":"pending"}},[_vm._v(_vm._s(_vm.$i18n.t('labels.status.pending')))]),_c(VBtn,{attrs:{"value":"enabled"}},[_vm._v(_vm._s(_vm.$i18n.t('labels.status.enabled')))]),_c(VBtn,{attrs:{"value":"disabled"}},[_vm._v(_vm._s(_vm.$i18n.t('labels.status.disabled')))]),_c(VBtn,{attrs:{"value":"rejected"}},[_vm._v(_vm._s(_vm.$i18n.t('labels.status.rejected')))])],1),_c(VSelect,{staticClass:"show--mobile",attrs:{"items":[
            { text: _vm.$i18n.t('labels.status.pending'), value: 'pending' },
            { text: _vm.$i18n.t('labels.status.enabled'), value: 'enabled' },
            { text: _vm.$i18n.t('labels.status.disabled'), value: 'disabled' },
            { text: _vm.$i18n.t('labels.status.rejected'), value: 'rejected' },
          ],"label":_vm.$tt.capitalize(_vm.$t('labels.type')),"rules":_vm.valid,"outlined":"","hide-details":""},model:{value:(_vm.user.status),callback:function ($$v) {_vm.$set(_vm.user, "status", $$v)},expression:"user.status"}})],1)],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('f-group-container',{staticClass:"user-lang"},[_c('p',[_vm._v(_vm._s(_vm.$i18n.t('labels.language')))]),_c(VBtnToggle,{staticClass:"show--desktop",on:{"change":_vm.onChangeLang},model:{value:(_vm.user.lang),callback:function ($$v) {_vm.$set(_vm.user, "lang", $$v)},expression:"user.lang"}},[_c(VBtn,{attrs:{"value":"pt"}},[_vm._v(_vm._s(_vm.$t('loginPage.langs.portuguese')))]),_c(VBtn,{attrs:{"value":"en"}},[_vm._v(_vm._s(_vm.$t('loginPage.langs.english')))]),_c(VBtn,{attrs:{"value":"es"}},[_vm._v(_vm._s(_vm.$t('loginPage.langs.spanish')))])],1),_c(VSelect,{staticClass:"show--mobile",attrs:{"items":[
            { text: _vm.$t('loginPage.langs.portuguese'), value: 'pt' },
            { text: _vm.$t('loginPage.langs.english'), value: 'en' },
            { text: _vm.$t('loginPage.langs.spanish'), value: 'es' },
          ],"label":_vm.$tt.capitalize(_vm.$t('labels.type')),"rules":_vm.valid,"outlined":"","hide-details":""},model:{value:(_vm.user.lang),callback:function ($$v) {_vm.$set(_vm.user, "lang", $$v)},expression:"user.lang"}})],1)],1)],1),(_vm.$store.state.auth.user.type !== 'student')?_c(VRow,[_c(VCol,{attrs:{"span":"12"}},[(_vm.flags.isEditing)?_c('div',[_c(VBtn,{attrs:{"color":"error","large":"","elevation":"0"},on:{"click":_vm.resetPass}},[_vm._v(" "+_vm._s(_vm.$t('labels.resetPassword'))+" ")])],1):_vm._e(),(!_vm.flags.isEditing)?_c(VTextField,{ref:"user-edit-password",attrs:{"id":"user-edit-password","outlined":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"name":"user-edit-password","label":_vm.$tt.capitalize(_vm.$t('labels.password'))},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }