<template>
  <div>
    <v-col cols="12">
      <p>Act as a champion?</p>
      <v-radio-group
        v-model="user.isChampion"
        mandatory
        @change="setDateActChampion"
      >
        <v-radio label="Yes" :value="true"></v-radio>
        <v-radio label="No" :value="false"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" md="4">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="user.championSince"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :disabled="!enabledChampion"
            v-model="user.championSince"
            label="Act as champion since?"
            prepend-icon="mdi-calendar"
            outlined
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="user.championSince"
          type="month"
          no-title
          scrollable
          :dark="true"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(user.championSince)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </div>
</template>

<script>
export default {
  data: () => ({
    enabledChampion: false,
    menu: false,
  }),
  computed: {
    user() {
      return this.$store.state.user.view;
    },
  },
  methods: {
    setDateActChampion() {
      this.enabledChampion = this.user.isChampion;
      this.user.championSince = '';
    },
  },
};
</script>
