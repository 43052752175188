import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCol,{attrs:{"cols":"12"}},[_c('p',[_vm._v("Act as a champion?")]),_c(VRadioGroup,{attrs:{"mandatory":""},on:{"change":_vm.setDateActChampion},model:{value:(_vm.user.isChampion),callback:function ($$v) {_vm.$set(_vm.user, "isChampion", $$v)},expression:"user.isChampion"}},[_c(VRadio,{attrs:{"label":"Yes","value":true}}),_c(VRadio,{attrs:{"label":"No","value":false}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.user.championSince,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.user, "championSince", $event)},"update:return-value":function($event){return _vm.$set(_vm.user, "championSince", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"disabled":!_vm.enabledChampion,"label":"Act as champion since?","prepend-icon":"mdi-calendar","outlined":"","readonly":""},model:{value:(_vm.user.championSince),callback:function ($$v) {_vm.$set(_vm.user, "championSince", $$v)},expression:"user.championSince"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"type":"month","no-title":"","scrollable":"","dark":true},model:{value:(_vm.user.championSince),callback:function ($$v) {_vm.$set(_vm.user, "championSince", $$v)},expression:"user.championSince"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.user.championSince)}}},[_vm._v(" OK ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }