<template>
  <div>
    <!-- Removido para faciliar a seleçào e validaçào -->
    <!-- <v-btn-toggle v-model="user.type" class="show--desktop" required>
      <v-btn value="student">{{ $i18n.t('labels.student') }}</v-btn>
      <v-btn value="master" v-if="showButtons.admin">Master</v-btn>
      <v-btn value="fact">FACT</v-btn>
      <v-btn value="tsm" v-if="showButtons.tsm">TSM</v-btn>
      <v-btn value="admin" v-if="showButtons.admin">Admin</v-btn>
    </v-btn-toggle> -->
    <v-select
      v-model="user.type"
      :items="[
        { text: $i18n.t('labels.student'), value: 'student' },
        { text: 'Master', value: 'master' },
        { text: 'FACT', value: 'fact' },
        { text: 'TSM', value: 'tsm' },
        { text: 'Admin', value: 'admin' },
      ]"
      :label="$tt.capitalize($t('labels.userType'))"
      :rules="valid"
      required
      outlined
      hide-details
    />
    <br />
    <br />
    <f-master-select
      v-if="showSelect.master"
      v-model="user.masters"
      :isMultiple="false"
    />

    <f-master-select
      v-if="user.type == 'student' || user.type == 'fact'"
      v-model="user.masters"
      :isMultiple="false"
    />

    <div v-if="user.type == 'tsm'">
      <v-select
        v-model="user.TSM.countries"
        item-value="_id"
        item-text="name"
        :items="countriesList"
        :label="$tt.capitalize($t('labels.country'))"
        :rules="valid"
        required
        @change="setTSMRegion()"
        outlined
        multiple
        chips
      />
      <div v-if="user.TSM.countries.includes(brazilCountryID) !== false">
        <v-select
          v-model="user.TSM.regions"
          :items="TSMStates"
          :label="$tt.capitalize($t('labels.states'))"
          outlined
          multiple
          chips
        />
      </div>
    </div>
  </div>
</template>

<script>
import FMasterSelect from '../../shared/MasterSelect.vue';
import validatorRules from '../../../helpers/validator_rules';
import { UserType } from '../../../helpers/user_utils';

export default {
  components: {
    FMasterSelect,
  },
  data: () => ({
    TSMStates: [],
    brazilCountryID: '',
  }),
  computed: {
    showButtons() {
      let buttons = {
        tsm: false,
        admin: false,
      };

      if (this.authUser.type == UserType.Admin) {
        buttons.tsm = buttons.admin = true;
      }

      return buttons;
    },
    showSelect() {
      let selects = {
        master: false,
      };

      if (
        this.user.type == UserType.Master &&
        (this.authUser.type == UserType.Admin ||
          this.authUser.type == UserType.TSM)
      ) {
        selects.master = true;
      }

      return selects;
    },
    authUser() {
      return this.$store.getters['auth/user'];
    },
    user() {
      return this.$store.state.user.view;
    },
    countriesList() {
      const countries = this.$store.state.country.list;
      if (typeof countries.count != 'undefined' && countries.count > 0) {
        var list = countries.items.map(country => {
          if (country.name.en == 'BRAZIL') {
            this.brazilCountryID = country._id;
          }
          return {
            _id: country._id,
            name: country.name[this.$i18n.locale],
          };
        });
        return list;
      } else return [];
    },
    valid() {
      return validatorRules.required(this.$t('rules.required'));
    },
  },
  created() {
    this.$store.dispatch('country/loadList');
  },
  methods: {
    async setTSMRegion() {
      if (this.user.TSM.countries.includes(this.brazilCountryID)) {
        const result = await this.$store.dispatch(
          'country/listStates',
          this.brazilCountryID
        );
        this.TSMStates = result;
      } else {
        this.TSMStates = [];
        this.user.TSM.regions = [];
      }
    },
  },
};
</script>
